
import Vue from "vue";
import { Component, Prop, Watch, Ref } from "vue-property-decorator";
import setMap from "./json/setMap";
import { namespace } from "vuex-class";
import { title } from "process";
// 可选type列表
let typeList = Object.keys(setMap),
  // 配置字段
  fieldList = Object.keys(setMap.move);

const TeamModule = namespace("team");
const UserModule = namespace("user");

@Component({
  components: {},
  filters: {
    //局部过滤器
  },
})
export default class ClassComponent extends Vue {
  @TeamModule.State("teamList") teamList!: any[];
  @UserModule.State("personalMailboxEnterprise") personalMailboxEnterprise!:boolean;
  teamFlag: boolean = false;
  title: string = "";
  isMoble:boolean = false;
  teamName:string=''
  selItem: any = {};
  loading: boolean = false;
  loadingTeam: boolean = false;
  selMenuPath: string = "";
  folderList: any[] = [];
  rightMenuSelIndex: number = NaN;
  addfolderShow: boolean = false;
  newFolderName: string = "";
  breadcrumbList: any[] = [];
  menuList: any = [
    {
      title: "个人文档",
      icon: "document",
      action: false,
      path: "/star/mydoc",
      routeNmae: "VIEW_MY_DOCUMENT",
      moduleType: "doc",
    },
    {

      title: "企业团队",
      icon: "menuTeam",
      action: false,
      path: "/*",
      routeNmae: "",
      moduleType: "team",
      teamList: [],
    },
  ];
  teamId: string = "";

  @Prop({
    type: String,
    default: "publicShare",
    validator(value: string) {
      return typeList.includes(value);
    },
  })
  type!: string;

  @Prop({
    type: Boolean,
    default: false,
  })
  visible!: boolean;

  @Prop({
    type: Object,
    default: () => { },
  })
  options!: any;
  @Prop({
    type:Boolean,
    default:true
  })
  isTeam!:boolean
  @Prop({
    type: String,
    default: "doc",
  })
  moduleType!: string;

  modifiedModuleType: string = this.moduleType;

  created() {
    this.isMoble = JSON.parse(localStorage.isMobile)
    console.log("create", this.options, this.$route, this.moduleType);
    // this.teamId = this.options.item.teamId || this.$route.query.teamId;
    console.log(this.type)
    if (this.type === 'copy') this.menuList[1].teamList = this.teamList;
    // 目前不支持各模块之间的文件移动,复制  根据所选模块进行单个展示
    // this.menuList = [
    //   this.menuList.find((i: any) => i.moduleType === this.moduleType),
    // ];
    // this.getFolderList();




    if (Array.isArray(this.menuList)) {
      const enterpriseTeamItem = this.menuList.find(item => item.moduleType == "team");
      if (enterpriseTeamItem) {
        if (!this.teamList.length) {
          console.log('this.$store.state.user.companyId',);
          let companyId = this.$store.state.user.companyId
          this.$apis.companyTeamPageList({
            companyId,
            isPage: 0,
          }).then((res: any) => {
            if (res.code === '200') {
              enterpriseTeamItem.teamList = res.data.list
            }
          })
        } else {
          enterpriseTeamItem.teamList = this.teamList;
        }
      }
    }



  }

  mounted() {
    console.log('this.teamList', this.teamList);
    console.log('this.menuList', this.menuList);
    this.leftMenuClick(this.menuList[0]);
    // console.log(this.type)
  }

  showItemFn(item:any){
    if(this.personalMailboxEnterprise) {
        if(item.title=='企业团队') {
          return false
        } else {
          return true
        }
    } else{
      return true
    }
  }

  getParentId(){
    this.$emit('getParentId',{id:this.selItem?.id})
  }
  // 选择团队
  selectTeam(team: any,flag?:any) {
    if(flag){
      this.updateModuleType('team')
    this.teamName =team.teamName;
    console.log(' this.modifiedModuleType', this.modifiedModuleType);
    this.teamFlag = true
    this.selMenuPath = 'team'
    this.teamId = team.id;
    console.log('this.teamId', this.teamId);

    this.breadcrumbList = [
      {
        userFileName:this.teamName
      }
    ];
    this.selItem = {};
    // this.selItem = team;
    this.getFolderList();
      return
    }
    // this.moduleType='team'
    this.updateModuleType('team')
    this.teamName =team.teamName
    this.selMenuPath = 'team'
    console.log(' this.modifiedModuleType', this.modifiedModuleType);
    this.teamFlag = true
    this.teamId = team.id;
    console.log('this.teamId', this.teamId);

    this.breadcrumbList = [];
    this.selItem = {};
    // this.selItem = team;
    this.getFolderList();
  }

  updateModuleType(val: string) {
    this.modifiedModuleType = val;
  }

  // 获取文件列表
  async getFolderList(id?: any, selectId?: string) {
    this.rightMenuSelIndex = NaN;
    this.folderList = [];
    this.loading = true;
    if (this.modifiedModuleType === "doc") {
      await this.$apis
        .userFilePageList({ id: id ? id : "" })
        .then((res: any) => {
          res.list.forEach((element: any) => {
            if (element.isDir == "1") {
              this.folderList.push(element);
            }
          });
        });
    } else {
      await this.$apis
        .companyFilePageList({
          id: id ? id : "",
          teamId: this.teamId,
          isPage: 0,
        })
        .then((res: any) => {
          res.data.list.forEach((element: any) => {
            if (element.isDir == "1") {
              this.folderList.push(element);
            }
          });
        });
    }
    if (selectId) {
      let selectFolder = {
        value: {},
        index: NaN,
      };
      this.folderList.forEach((item, index) => {
        if (item.id === selectId) {
          selectFolder = {
            value: item,
            index: index,
          };
        }
      });
      this.rightMenuClick(selectFolder.value, selectFolder.index);
    }
    this.loading = false;
  }

  onClickCancle() {
    this.$emit("close", false);
  }

  leftMenuClick(item: any) {
   const items = this.menuList.find((i:any)=>i.title===item.title)
    this.teamId = ''

    this.updateModuleType(items.moduleType)

    console.log('---this.modifiedModuleType', this.modifiedModuleType);

    if (this.modifiedModuleType == 'doc') {

      this.teamName =this.selMenuPath = items.title;
    }
    this.breadcrumbList = [];
    this.selItem = {};
    // console.log(item,this.selMenuPath)
    if (this.modifiedModuleType == 'doc') {
      this.getFolderList();
    } else {
      // alert('点到了企业团队')
      this.loadingTeam = true
      let companyId = this.$store.state.user.companyId
      // this.$store.dispatch('team/GET_TEAMLIST',companyId)
      this.$apis.companyTeamPageList({
        companyId
      }).then((res: any) => {
        if (res.code === '200') {
          this.$store.commit('team/SET_TEAMLIST', res.data.list)
          setTimeout(() => {
            this.loadingTeam = false
          }, 500);
        }
      })
    }
  }

  rightMenuClick(item: any, index: number,flag?:any) {
    console.log(item);
    if (item == this.selItem) {
      this.rightMenuSelIndex = NaN;
      this.selItem = {};
    } else {
      this.rightMenuSelIndex = index;
      this.selItem = item;
    }
    if(flag){
      this.dbClickDir(item)
    }
  }

  dbClickDir(item: any) {
    console.log(item,444)
    if (item != this.breadcrumbList.slice(-1)[0]) {
      this.breadcrumbList.push(item);
    }
    this.getFolderList(item.id);
    this.selItem = item;
  }

  BackToPrevious() {
    console.log(
      "breadcrumbClick",
      this.breadcrumbList,
      this.breadcrumbList.slice(-2, -1)
    );
    let i = this.breadcrumbList.slice(-2, -1)[0];
    this.breadcrumbList.splice(-1, 1);
    this.getFolderList(i.id);
    this.selItem = i;
  }

  breadcrumbClick(item: any, index: any) {
    console.log(item, index);
    if (item == this.breadcrumbList.slice(-1)[0]) {
      return;
    }
    this.breadcrumbList.splice(
      index + 1,
      this.breadcrumbList.length - index - 1
    );
    this.getFolderList(item.id);
    this.selItem = item;

  }

  showAddFolder() {
    this.addfolderShow = true;
  }

  cancleAdd() {
    this.addfolderShow = false;
  }

  // 添加文件夹
  addfolder() {
    const apiName =
      this.modifiedModuleType === "team" ? "companyFileAddFolder" : "userFileAddFolder";
    const params =
      this.modifiedModuleType === "team"
        ? {
          userFileName: this.newFolderName,
          parentId: this.selItem.id || "",
          teamId: this.teamId,
        }
        : {
          userFileName: this.newFolderName,
          parentId: this.selItem.id || "",
        };
    this.$apis[apiName](params).then((res: any) => {
      this.addfolderShow = false;
      if (this.breadcrumbList.length > 0) {
        this.getFolderList(this.breadcrumbList.slice(-1)[0].id, res.data);
      } else {
        this.getFolderList("", res.data);
      }
    });
  }

  // 文件移动
  fileMove() {
    const apiName =
      this.modifiedModuleType === "team" ? "companyFileMove" : "userFileMoves";
    const params =
      this.modifiedModuleType === "team"
        ? {
          sourceIds: this.options.checkIdList,
          targetId: this.selItem.id || "",
          teamId: this.teamId,
        }
        : {
          sourceIds: this.options.checkIdList,
          targetId: this.selItem.id || "",
        };

    if (this.modifiedModuleType === "team") {
      // 团队文件校验权限
      this.$apis
        .companyFileCheck({
          sourceIds: this.options.checkIdList,
          teamId: this.teamId,
        })
        .then((res: any) => {
          if (res.code === "200") {
            // data为无权限文件名的数组
            if (!res.data || res.data.length === 0) {
              this.$apis
                .companyFileMove({
                  sourceIds: this.options.checkIdList,
                  targetId: this.selItem.id || "",
                  teamId: this.teamId,
                })
                .then((res: any) => {
                  if (res.code == 200) {
                    this.$message.success("移动成功");
                    this.$emit("close", true);
                  }
                });
            } else {
              const that = this;
              const h = this.$createElement;
              this.$confirm({
                title: "文件移动",
                content: h("div", {}, [
                  h(
                    "p",
                    "以下文件无操作权限,是否继续执行移动(无操作权限无法移动)"
                  ),
                  h("p", `无操作权限文件：${res.data.join("、")}`),
                ]),
                okText: "继续移动",
                cancelText: "取消",
                onOk() {
                  that.$apis
                    .companyFileMove({
                      sourceIds: that.options.checkIdList,
                      targetId: that.selItem.id || "",
                      teamId: that.teamId,
                    })
                    .then((res: any) => {
                      if (res.code == 200) {
                        that.$message.success("移动成功");
                        that.$emit("close", true);
                      }
                    });
                },
                onCancel() {
                  console.log("Cancel");
                },
              });
            }
          }
        });
    } else {
      this.$apis
        .userFileMoves({
          sourceIds: this.options.checkIdList,
          targetId: this.selItem.id || "",
        })
        .then((res: any) => {
          if (res.code == 200) {
            this.$message.success("移动成功");
            this.$emit("close", true);
          }
        });
    }
  }

  // 文件复制
  fileCopy() {
    const apiName =
      this.modifiedModuleType === "team" ? "companyFileCopy" : "userFileCopy";
    const params =
      this.modifiedModuleType === "team"
        ? {
          sourceIds: this.options.checkIdList,
          targetId: this.selItem.id || "",
          teamId: this.teamId,
        }
        : {
          sourceIds: this.options.checkIdList,
          targetId: this.selItem.id || "",
        };
    this.$apis[apiName](params).then((res: any) => {
      if (res.code == 200) {
        this.$message.success("复制成功");
        this.$emit("close", true);
      }
    });
  }

  // 文件上传
  fileUploader() {
    this.$emit("uploader", this.selItem.id || "");
  }

  // 文件保存
  fileSave() {
    if (this.modifiedModuleType === "team") {
      this.$emit("save", this.selItem.id || "", this.options.checkIdList, this.teamId);
    } else {
      this.$emit("save", this.selItem.id || "", this.options.checkIdList);
    }
    this.$emit('saves',{...this.selItem,parentTitle:this.teamName,teamId:this.teamId})
  }

  @Watch("type", { immediate: true })
  typeChange(el: string) {
    //@ts-ignore
    if (el && setMap[el]) {
      fieldList.forEach((key: string) => {
        //@ts-ignore
        if (this[key] !== undefined) this[key] = setMap[el][key] || "";
      });
    }
  }
}
